// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/values/v1/values.proto (package autokitteh.values.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration as Duration$1, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Function as Function$1 } from "../../module/v1/module_pb.js";

/**
 * @generated from message autokitteh.values.v1.Nothing
 */
export class Nothing extends Message<Nothing> {
  constructor(data?: PartialMessage<Nothing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Nothing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Nothing {
    return new Nothing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Nothing {
    return new Nothing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Nothing {
    return new Nothing().fromJsonString(jsonString, options);
  }

  static equals(a: Nothing | PlainMessage<Nothing> | undefined, b: Nothing | PlainMessage<Nothing> | undefined): boolean {
    return proto3.util.equals(Nothing, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.String
 */
export class String extends Message<String> {
  /**
   * @generated from field: string v = 1;
   */
  v = "";

  constructor(data?: PartialMessage<String>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.String";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): String {
    return new String().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): String {
    return new String().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): String {
    return new String().fromJsonString(jsonString, options);
  }

  static equals(a: String | PlainMessage<String> | undefined, b: String | PlainMessage<String> | undefined): boolean {
    return proto3.util.equals(String, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Integer
 */
export class Integer extends Message<Integer> {
  /**
   * @generated from field: int64 v = 1;
   */
  v = protoInt64.zero;

  constructor(data?: PartialMessage<Integer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Integer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Integer {
    return new Integer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Integer {
    return new Integer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Integer {
    return new Integer().fromJsonString(jsonString, options);
  }

  static equals(a: Integer | PlainMessage<Integer> | undefined, b: Integer | PlainMessage<Integer> | undefined): boolean {
    return proto3.util.equals(Integer, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Float
 */
export class Float extends Message<Float> {
  /**
   * @generated from field: double v = 1;
   */
  v = 0;

  constructor(data?: PartialMessage<Float>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Float";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Float {
    return new Float().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Float {
    return new Float().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Float {
    return new Float().fromJsonString(jsonString, options);
  }

  static equals(a: Float | PlainMessage<Float> | undefined, b: Float | PlainMessage<Float> | undefined): boolean {
    return proto3.util.equals(Float, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Boolean
 */
export class Boolean extends Message<Boolean> {
  /**
   * @generated from field: bool v = 1;
   */
  v = false;

  constructor(data?: PartialMessage<Boolean>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Boolean";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Boolean {
    return new Boolean().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Boolean {
    return new Boolean().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Boolean {
    return new Boolean().fromJsonString(jsonString, options);
  }

  static equals(a: Boolean | PlainMessage<Boolean> | undefined, b: Boolean | PlainMessage<Boolean> | undefined): boolean {
    return proto3.util.equals(Boolean, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Symbol
 */
export class Symbol extends Message<Symbol> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<Symbol>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Symbol";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Symbol {
    return new Symbol().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Symbol {
    return new Symbol().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Symbol {
    return new Symbol().fromJsonString(jsonString, options);
  }

  static equals(a: Symbol | PlainMessage<Symbol> | undefined, b: Symbol | PlainMessage<Symbol> | undefined): boolean {
    return proto3.util.equals(Symbol, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.List
 */
export class List extends Message<List> {
  /**
   * @generated from field: repeated autokitteh.values.v1.Value vs = 1;
   */
  vs: Value[] = [];

  constructor(data?: PartialMessage<List>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.List";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vs", kind: "message", T: Value, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): List {
    return new List().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): List {
    return new List().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): List {
    return new List().fromJsonString(jsonString, options);
  }

  static equals(a: List | PlainMessage<List> | undefined, b: List | PlainMessage<List> | undefined): boolean {
    return proto3.util.equals(List, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Set
 */
export class Set extends Message<Set> {
  /**
   * @generated from field: repeated autokitteh.values.v1.Value vs = 1;
   */
  vs: Value[] = [];

  constructor(data?: PartialMessage<Set>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Set";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vs", kind: "message", T: Value, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Set {
    return new Set().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Set {
    return new Set().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Set {
    return new Set().fromJsonString(jsonString, options);
  }

  static equals(a: Set | PlainMessage<Set> | undefined, b: Set | PlainMessage<Set> | undefined): boolean {
    return proto3.util.equals(Set, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Bytes
 */
export class Bytes extends Message<Bytes> {
  /**
   * @generated from field: bytes v = 1;
   */
  v = new Uint8Array(0);

  constructor(data?: PartialMessage<Bytes>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Bytes";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Bytes {
    return new Bytes().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Bytes {
    return new Bytes().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Bytes {
    return new Bytes().fromJsonString(jsonString, options);
  }

  static equals(a: Bytes | PlainMessage<Bytes> | undefined, b: Bytes | PlainMessage<Bytes> | undefined): boolean {
    return proto3.util.equals(Bytes, a, b);
  }
}

/**
 * Dict cannot be a simple map since the keys might be values themselves.
 *
 * @generated from message autokitteh.values.v1.Dict
 */
export class Dict extends Message<Dict> {
  /**
   * @generated from field: repeated autokitteh.values.v1.Dict.Item items = 1;
   */
  items: Dict_Item[] = [];

  constructor(data?: PartialMessage<Dict>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Dict";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: Dict_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Dict {
    return new Dict().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Dict {
    return new Dict().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Dict {
    return new Dict().fromJsonString(jsonString, options);
  }

  static equals(a: Dict | PlainMessage<Dict> | undefined, b: Dict | PlainMessage<Dict> | undefined): boolean {
    return proto3.util.equals(Dict, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Dict.Item
 */
export class Dict_Item extends Message<Dict_Item> {
  /**
   * @generated from field: autokitteh.values.v1.Value k = 1;
   */
  k?: Value;

  /**
   * @generated from field: autokitteh.values.v1.Value v = 2;
   */
  v?: Value;

  constructor(data?: PartialMessage<Dict_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Dict.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "k", kind: "message", T: Value },
    { no: 2, name: "v", kind: "message", T: Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Dict_Item {
    return new Dict_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Dict_Item {
    return new Dict_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Dict_Item {
    return new Dict_Item().fromJsonString(jsonString, options);
  }

  static equals(a: Dict_Item | PlainMessage<Dict_Item> | undefined, b: Dict_Item | PlainMessage<Dict_Item> | undefined): boolean {
    return proto3.util.equals(Dict_Item, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Time
 */
export class Time extends Message<Time> {
  /**
   * @generated from field: google.protobuf.Timestamp v = 1;
   */
  v?: Timestamp;

  constructor(data?: PartialMessage<Time>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Time";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Time {
    return new Time().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Time {
    return new Time().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Time {
    return new Time().fromJsonString(jsonString, options);
  }

  static equals(a: Time | PlainMessage<Time> | undefined, b: Time | PlainMessage<Time> | undefined): boolean {
    return proto3.util.equals(Time, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Duration
 */
export class Duration extends Message<Duration> {
  /**
   * @generated from field: google.protobuf.Duration v = 1;
   */
  v?: Duration$1;

  constructor(data?: PartialMessage<Duration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Duration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "v", kind: "message", T: Duration$1 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Duration {
    return new Duration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Duration {
    return new Duration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Duration {
    return new Duration().fromJsonString(jsonString, options);
  }

  static equals(a: Duration | PlainMessage<Duration> | undefined, b: Duration | PlainMessage<Duration> | undefined): boolean {
    return proto3.util.equals(Duration, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Struct
 */
export class Struct extends Message<Struct> {
  /**
   * @generated from field: autokitteh.values.v1.Value ctor = 1;
   */
  ctor?: Value;

  /**
   * @generated from field: map<string, autokitteh.values.v1.Value> fields = 2;
   */
  fields: { [key: string]: Value } = {};

  constructor(data?: PartialMessage<Struct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Struct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ctor", kind: "message", T: Value },
    { no: 2, name: "fields", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Value} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Struct {
    return new Struct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Struct {
    return new Struct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Struct {
    return new Struct().fromJsonString(jsonString, options);
  }

  static equals(a: Struct | PlainMessage<Struct> | undefined, b: Struct | PlainMessage<Struct> | undefined): boolean {
    return proto3.util.equals(Struct, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Module
 */
export class Module extends Message<Module> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: map<string, autokitteh.values.v1.Value> members = 2;
   */
  members: { [key: string]: Value } = {};

  constructor(data?: PartialMessage<Module>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Module";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "members", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Value} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Module {
    return new Module().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Module {
    return new Module().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Module {
    return new Module().fromJsonString(jsonString, options);
  }

  static equals(a: Module | PlainMessage<Module> | undefined, b: Module | PlainMessage<Module> | undefined): boolean {
    return proto3.util.equals(Module, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Function
 */
export class Function extends Message<Function> {
  /**
   * executor identifies who owns the execution of the function.
   * essentially, where the function is defined. that entity
   * is responsible of the execution of the function.
   *
   * @generated from field: string executor_id = 1;
   */
  executorId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: autokitteh.module.v1.Function desc = 3;
   */
  desc?: Function$1;

  /**
   * Data that is transfered along with the function itself.
   * This can be used by the function implementing the name
   * above, to store data in its internal scope.
   *
   * @generated from field: bytes data = 4;
   */
  data = new Uint8Array(0);

  /**
   * @generated from field: repeated string flags = 5;
   */
  flags: string[] = [];

  constructor(data?: PartialMessage<Function>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Function";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "executor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "desc", kind: "message", T: Function$1 },
    { no: 4, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "flags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Function {
    return new Function().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Function {
    return new Function().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Function {
    return new Function().fromJsonString(jsonString, options);
  }

  static equals(a: Function | PlainMessage<Function> | undefined, b: Function | PlainMessage<Function> | undefined): boolean {
    return proto3.util.equals(Function, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Custom
 */
export class Custom extends Message<Custom> {
  /**
   * Executor used to generate this data.
   *
   * @generated from field: string executor_id = 1;
   */
  executorId = "";

  /**
   * Data generated and comprehensible only to the generating executor.
   *
   * @generated from field: bytes data = 2;
   */
  data = new Uint8Array(0);

  /**
   * Value representation of this data for display and cross-executor compatibility.
   *
   * @generated from field: autokitteh.values.v1.Value value = 3;
   */
  value?: Value;

  constructor(data?: PartialMessage<Custom>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Custom";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "executor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "value", kind: "message", T: Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Custom {
    return new Custom().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Custom {
    return new Custom().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Custom {
    return new Custom().fromJsonString(jsonString, options);
  }

  static equals(a: Custom | PlainMessage<Custom> | undefined, b: Custom | PlainMessage<Custom> | undefined): boolean {
    return proto3.util.equals(Custom, a, b);
  }
}

/**
 * @generated from message autokitteh.values.v1.Value
 */
export class Value extends Message<Value> {
  /**
   * one of the following fields must be set.
   *
   * @generated from field: autokitteh.values.v1.Nothing nothing = 1;
   */
  nothing?: Nothing;

  /**
   * @generated from field: autokitteh.values.v1.Boolean boolean = 2;
   */
  boolean?: Boolean;

  /**
   * @generated from field: autokitteh.values.v1.String string = 3;
   */
  string?: String;

  /**
   * @generated from field: autokitteh.values.v1.Integer integer = 4;
   */
  integer?: Integer;

  /**
   * @generated from field: autokitteh.values.v1.Float float = 5;
   */
  float?: Float;

  /**
   * @generated from field: autokitteh.values.v1.List list = 6;
   */
  list?: List;

  /**
   * @generated from field: autokitteh.values.v1.Set set = 7;
   */
  set?: Set;

  /**
   * @generated from field: autokitteh.values.v1.Dict dict = 8;
   */
  dict?: Dict;

  /**
   * @generated from field: autokitteh.values.v1.Bytes bytes = 9;
   */
  bytes?: Bytes;

  /**
   * @generated from field: autokitteh.values.v1.Time time = 10;
   */
  time?: Time;

  /**
   * @generated from field: autokitteh.values.v1.Duration duration = 11;
   */
  duration?: Duration;

  /**
   * @generated from field: autokitteh.values.v1.Struct struct = 12;
   */
  struct?: Struct;

  /**
   * @generated from field: autokitteh.values.v1.Module module = 13;
   */
  module?: Module;

  /**
   * @generated from field: autokitteh.values.v1.Symbol symbol = 14;
   */
  symbol?: Symbol;

  /**
   * @generated from field: autokitteh.values.v1.Function function = 15;
   */
  function?: Function;

  /**
   * @generated from field: autokitteh.values.v1.Custom custom = 16;
   */
  custom?: Custom;

  constructor(data?: PartialMessage<Value>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.values.v1.Value";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nothing", kind: "message", T: Nothing },
    { no: 2, name: "boolean", kind: "message", T: Boolean },
    { no: 3, name: "string", kind: "message", T: String },
    { no: 4, name: "integer", kind: "message", T: Integer },
    { no: 5, name: "float", kind: "message", T: Float },
    { no: 6, name: "list", kind: "message", T: List },
    { no: 7, name: "set", kind: "message", T: Set },
    { no: 8, name: "dict", kind: "message", T: Dict },
    { no: 9, name: "bytes", kind: "message", T: Bytes },
    { no: 10, name: "time", kind: "message", T: Time },
    { no: 11, name: "duration", kind: "message", T: Duration },
    { no: 12, name: "struct", kind: "message", T: Struct },
    { no: 13, name: "module", kind: "message", T: Module },
    { no: 14, name: "symbol", kind: "message", T: Symbol },
    { no: 15, name: "function", kind: "message", T: Function },
    { no: 16, name: "custom", kind: "message", T: Custom },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Value {
    return new Value().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Value {
    return new Value().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Value {
    return new Value().fromJsonString(jsonString, options);
  }

  static equals(a: Value | PlainMessage<Value> | undefined, b: Value | PlainMessage<Value> | undefined): boolean {
    return proto3.util.equals(Value, a, b);
  }
}

