export enum ModalName {
	addCodeAssets = "addCodeAssets",
	deleteConnection = "deleteConnection",
	deleteDeployment = "deleteDeployment",
	deleteDeploymentSession = "deleteDeploymentSession",
	deleteFile = "deleteFile",
	deleteAccount = "deleteAccount",
	deleteTrigger = "deleteTrigger",
	deleteVariable = "deleteVariable",
	getToken = "getToken",
	deleteProject = "deleteProject",
	welcomePage = "welcomePage",
	templateCreateProject = "templateCreateProject",
	newProject = "newProject",
	importProject = "importProject",
	warningDeploymentActive = "warningDeploymentActive",
}
