// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/integrations/v1/integration.proto (package autokitteh.integrations.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Module } from "../../module/v1/module_pb.js";
import { Capabilities } from "../../connections/v1/connection_pb.js";
import { Status } from "../../common/v1/status_pb.js";

/**
 * @generated from message autokitteh.integrations.v1.Integration
 */
export class Integration extends Message<Integration> {
  /**
   * Unique internal identifier. Required, except in creation requests.
   *
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * Unique external (and URL-safe) identifier. Required.
   *
   * @generated from field: string unique_name = 2;
   */
  uniqueName = "";

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: string logo_url = 5;
   */
  logoUrl = "";

  /**
   * @generated from field: map<string, string> user_links = 6;
   */
  userLinks: { [key: string]: string } = {};

  /**
   * TODO(ENG-346): Connection UI specification instead of a URL.
   *
   * @generated from field: string connection_url = 8;
   */
  connectionUrl = "";

  /**
   * @generated from field: autokitteh.module.v1.Module module = 9;
   */
  module?: Module;

  /**
   * @generated from field: autokitteh.connections.v1.Capabilities connection_capabilities = 10;
   */
  connectionCapabilities?: Capabilities;

  /**
   * @generated from field: autokitteh.common.v1.Status initial_connection_status = 11;
   */
  initialConnectionStatus?: Status;

  constructor(data?: PartialMessage<Integration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.Integration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unique_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "user_links", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "connection_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "module", kind: "message", T: Module },
    { no: 10, name: "connection_capabilities", kind: "message", T: Capabilities },
    { no: 11, name: "initial_connection_status", kind: "message", T: Status },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Integration {
    return new Integration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Integration {
    return new Integration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Integration {
    return new Integration().fromJsonString(jsonString, options);
  }

  static equals(a: Integration | PlainMessage<Integration> | undefined, b: Integration | PlainMessage<Integration> | undefined): boolean {
    return proto3.util.equals(Integration, a, b);
  }
}

