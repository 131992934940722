// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/events/v1/event.proto (package autokitteh.events.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Value } from "../../values/v1/values_pb.js";

/**
 * @generated from message autokitteh.events.v1.Event
 */
export class Event extends Message<Event> {
  /**
   * @generated from field: string event_id = 1;
   */
  eventId = "";

  /**
   * connection_id or trigger_id.
   *
   * @generated from field: string destination_id = 2;
   */
  destinationId = "";

  /**
   * @generated from field: string event_type = 3;
   */
  eventType = "";

  /**
   * @generated from field: map<string, autokitteh.values.v1.Value> data = 4;
   */
  data: { [key: string]: Value } = {};

  /**
   * @generated from field: map<string, string> memo = 5;
   */
  memo: { [key: string]: string } = {};

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: uint64 seq = 7;
   */
  seq = protoInt64.zero;

  constructor(data?: PartialMessage<Event>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.Event";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "event_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Value} },
    { no: 5, name: "memo", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "seq", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event {
    return new Event().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJsonString(jsonString, options);
  }

  static equals(a: Event | PlainMessage<Event> | undefined, b: Event | PlainMessage<Event> | undefined): boolean {
    return proto3.util.equals(Event, a, b);
  }
}

