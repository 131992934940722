export const namespaces = {
	authService: "Authorization Service",
	connectionService: "Connections Service",
	integrationService: "Integrations Service",
	triggersUI: "Triggers UI",
	deploymentsService: "Deployments Service",
	buildService: "Builds Service",
	environmentsService: "Environments Service",
	manifestService: "Manifest Service",
	projectService: "Project Service",
	projectUI: "Project UI",
	projectUICode: "Project UI - Code",
	resourcesService: "Resources Service",
	sessionsHistory: "Sessions History",
	sessionsService: "Sessions Service",
	triggerService: "Trigger Service",
	variableService: "Variable Service",
	buildRuntimeEntrypoints: "Convert runtime to entrypoints",
	eventsService: "Events Service",
	ui: {
		loginPage: "Login Page",
		connectionsTable: "Connections Table",
		sessionsTable: "Sessions Table",
		sessionsViewer: "Sessions Viewer",
		eventsViewer: "Sessions Viewer",
		profilePage: "Profile Page",
		deleteModal: "Delete Modal",
		projectCodeEditor: "Project Code Editor",
		code: "Code UI",
		triggers: "Triggers UI",
		deployments: "Deployments UI",
		connections: "Connections UI",
		variables: "Variables UI",
		sessions: "Sessions UI",
		manualRun: "Manual Run UI",
		menu: "Menu UI",
	},
	hooks: {
		connectionForm: "Connection Form",
		createProjectFromTemplate: "Create Project From Template",
	},
	stores: {
		connectionCheckerStore: "Connection Checker Store",
		cache: "Cache Store",
		activitiesStore: "Activity Logs Store",
		outputStore: "Output Logs Store",
		templatesStore: "Templates Store",
	},
	utilities: {
		fetchAndExtract: "Fetch and Extract",
	},
	models: {
		activity: "Activity Model",
	},
};
