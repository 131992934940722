export const defaultSystemLogSize = {
	initial: 0,
	max: 100,
	min: 0,
};

export const defaultSplitFrameSize = {
	max: 70,
	min: 35,
};
