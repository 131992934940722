// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/deployments/v1/deployment.proto (package autokitteh.deployments.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { SessionStateType } from "../../sessions/v1/session_pb.js";

/**
 * @generated from enum autokitteh.deployments.v1.DeploymentState
 */
export enum DeploymentState {
  /**
   * @generated from enum value: DEPLOYMENT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DEPLOYMENT_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: DEPLOYMENT_STATE_TESTING = 2;
   */
  TESTING = 2,

  /**
   * @generated from enum value: DEPLOYMENT_STATE_DRAINING = 3;
   */
  DRAINING = 3,

  /**
   * @generated from enum value: DEPLOYMENT_STATE_INACTIVE = 4;
   */
  INACTIVE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DeploymentState)
proto3.util.setEnumType(DeploymentState, "autokitteh.deployments.v1.DeploymentState", [
  { no: 0, name: "DEPLOYMENT_STATE_UNSPECIFIED" },
  { no: 1, name: "DEPLOYMENT_STATE_ACTIVE" },
  { no: 2, name: "DEPLOYMENT_STATE_TESTING" },
  { no: 3, name: "DEPLOYMENT_STATE_DRAINING" },
  { no: 4, name: "DEPLOYMENT_STATE_INACTIVE" },
]);

/**
 * @generated from message autokitteh.deployments.v1.Deployment
 */
export class Deployment extends Message<Deployment> {
  /**
   * immutable fields.
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string deployment_id = 2;
   */
  deploymentId = "";

  /**
   * @generated from field: string build_id = 3;
   */
  buildId = "";

  /**
   * mutable fields.
   *
   * @generated from field: autokitteh.deployments.v1.DeploymentState state = 4;
   */
  state = DeploymentState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 10;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 11;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: repeated autokitteh.deployments.v1.Deployment.SessionStats sessions_stats = 12;
   */
  sessionsStats: Deployment_SessionStats[] = [];

  constructor(data?: PartialMessage<Deployment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.deployments.v1.Deployment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deployment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "build_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(DeploymentState) },
    { no: 10, name: "created_at", kind: "message", T: Timestamp },
    { no: 11, name: "updated_at", kind: "message", T: Timestamp },
    { no: 12, name: "sessions_stats", kind: "message", T: Deployment_SessionStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Deployment {
    return new Deployment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Deployment {
    return new Deployment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Deployment {
    return new Deployment().fromJsonString(jsonString, options);
  }

  static equals(a: Deployment | PlainMessage<Deployment> | undefined, b: Deployment | PlainMessage<Deployment> | undefined): boolean {
    return proto3.util.equals(Deployment, a, b);
  }
}

/**
 * @generated from message autokitteh.deployments.v1.Deployment.SessionStats
 */
export class Deployment_SessionStats extends Message<Deployment_SessionStats> {
  /**
   * @generated from field: autokitteh.sessions.v1.SessionStateType state = 1;
   */
  state = SessionStateType.UNSPECIFIED;

  /**
   * @generated from field: uint32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<Deployment_SessionStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.deployments.v1.Deployment.SessionStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(SessionStateType) },
    { no: 2, name: "count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Deployment_SessionStats {
    return new Deployment_SessionStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Deployment_SessionStats {
    return new Deployment_SessionStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Deployment_SessionStats {
    return new Deployment_SessionStats().fromJsonString(jsonString, options);
  }

  static equals(a: Deployment_SessionStats | PlainMessage<Deployment_SessionStats> | undefined, b: Deployment_SessionStats | PlainMessage<Deployment_SessionStats> | undefined): boolean {
    return proto3.util.equals(Deployment_SessionStats, a, b);
  }
}

