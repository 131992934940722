// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file autokitteh/auth/v1/svc.proto (package autokitteh.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateTokenRequest, CreateTokenResponse, WhoAmIRequest, WhoAmIResponse } from "./svc_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service autokitteh.auth.v1.AuthService
 */
export const AuthService = {
  typeName: "autokitteh.auth.v1.AuthService",
  methods: {
    /**
     * @generated from rpc autokitteh.auth.v1.AuthService.WhoAmI
     */
    whoAmI: {
      name: "WhoAmI",
      I: WhoAmIRequest,
      O: WhoAmIResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.auth.v1.AuthService.CreateToken
     */
    createToken: {
      name: "CreateToken",
      I: CreateTokenRequest,
      O: CreateTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

