// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/integrations/v1/svc.proto (package autokitteh.integrations.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Integration } from "./integration_pb.js";
import { Value } from "../../values/v1/values_pb.js";
import { Error } from "../../program/v1/program_pb.js";
import { Status } from "../../common/v1/status_pb.js";

/**
 * @generated from message autokitteh.integrations.v1.GetRequest
 */
export class GetRequest extends Message<GetRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<GetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.GetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRequest {
    return new GetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRequest | PlainMessage<GetRequest> | undefined, b: GetRequest | PlainMessage<GetRequest> | undefined): boolean {
    return proto3.util.equals(GetRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.GetResponse
 */
export class GetResponse extends Message<GetResponse> {
  /**
   * @generated from field: autokitteh.integrations.v1.Integration integration = 1;
   */
  integration?: Integration;

  constructor(data?: PartialMessage<GetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.GetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration", kind: "message", T: Integration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponse {
    return new GetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponse | PlainMessage<GetResponse> | undefined, b: GetResponse | PlainMessage<GetResponse> | undefined): boolean {
    return proto3.util.equals(GetResponse, a, b);
  }
}

/**
 * All filering fields are optional.
 *
 * @generated from message autokitteh.integrations.v1.ListRequest
 */
export class ListRequest extends Message<ListRequest> {
  /**
   * @generated from field: string name_substring = 1;
   */
  nameSubstring = "";

  constructor(data?: PartialMessage<ListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.ListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name_substring", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRequest {
    return new ListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRequest {
    return new ListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRequest {
    return new ListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRequest | PlainMessage<ListRequest> | undefined, b: ListRequest | PlainMessage<ListRequest> | undefined): boolean {
    return proto3.util.equals(ListRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.ListResponse
 */
export class ListResponse extends Message<ListResponse> {
  /**
   * @generated from field: repeated autokitteh.integrations.v1.Integration integrations = 1;
   */
  integrations: Integration[] = [];

  constructor(data?: PartialMessage<ListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.ListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integrations", kind: "message", T: Integration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResponse {
    return new ListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResponse {
    return new ListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResponse {
    return new ListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListResponse | PlainMessage<ListResponse> | undefined, b: ListResponse | PlainMessage<ListResponse> | undefined): boolean {
    return proto3.util.equals(ListResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.CallRequest
 */
export class CallRequest extends Message<CallRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: autokitteh.values.v1.Value function = 2;
   */
  function?: Value;

  /**
   * @generated from field: repeated autokitteh.values.v1.Value args = 3;
   */
  args: Value[] = [];

  /**
   * @generated from field: map<string, autokitteh.values.v1.Value> kwargs = 4;
   */
  kwargs: { [key: string]: Value } = {};

  constructor(data?: PartialMessage<CallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.CallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "function", kind: "message", T: Value },
    { no: 3, name: "args", kind: "message", T: Value, repeated: true },
    { no: 4, name: "kwargs", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Value} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallRequest {
    return new CallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallRequest {
    return new CallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallRequest {
    return new CallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CallRequest | PlainMessage<CallRequest> | undefined, b: CallRequest | PlainMessage<CallRequest> | undefined): boolean {
    return proto3.util.equals(CallRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.CallResponse
 */
export class CallResponse extends Message<CallResponse> {
  /**
   * @generated from field: autokitteh.values.v1.Value value = 1;
   */
  value?: Value;

  /**
   * @generated from field: autokitteh.program.v1.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<CallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.CallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "message", T: Value },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallResponse {
    return new CallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallResponse {
    return new CallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallResponse {
    return new CallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CallResponse | PlainMessage<CallResponse> | undefined, b: CallResponse | PlainMessage<CallResponse> | undefined): boolean {
    return proto3.util.equals(CallResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.ConfigureRequest
 */
export class ConfigureRequest extends Message<ConfigureRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string connection_id = 2;
   */
  connectionId = "";

  constructor(data?: PartialMessage<ConfigureRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.ConfigureRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigureRequest {
    return new ConfigureRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigureRequest {
    return new ConfigureRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigureRequest {
    return new ConfigureRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigureRequest | PlainMessage<ConfigureRequest> | undefined, b: ConfigureRequest | PlainMessage<ConfigureRequest> | undefined): boolean {
    return proto3.util.equals(ConfigureRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.ConfigureResponse
 */
export class ConfigureResponse extends Message<ConfigureResponse> {
  /**
   * @generated from field: map<string, string> config = 1;
   */
  config: { [key: string]: string } = {};

  /**
   * @generated from field: map<string, autokitteh.values.v1.Value> values = 4;
   */
  values: { [key: string]: Value } = {};

  constructor(data?: PartialMessage<ConfigureResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.ConfigureResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "config", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "values", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Value} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigureResponse {
    return new ConfigureResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigureResponse {
    return new ConfigureResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigureResponse {
    return new ConfigureResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigureResponse | PlainMessage<ConfigureResponse> | undefined, b: ConfigureResponse | PlainMessage<ConfigureResponse> | undefined): boolean {
    return proto3.util.equals(ConfigureResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.TestConnectionRequest
 */
export class TestConnectionRequest extends Message<TestConnectionRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string connection_id = 2;
   */
  connectionId = "";

  constructor(data?: PartialMessage<TestConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.TestConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestConnectionRequest {
    return new TestConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestConnectionRequest {
    return new TestConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestConnectionRequest {
    return new TestConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TestConnectionRequest | PlainMessage<TestConnectionRequest> | undefined, b: TestConnectionRequest | PlainMessage<TestConnectionRequest> | undefined): boolean {
    return proto3.util.equals(TestConnectionRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.TestConnectionResponse
 */
export class TestConnectionResponse extends Message<TestConnectionResponse> {
  /**
   * @generated from field: autokitteh.common.v1.Status status = 1;
   */
  status?: Status;

  constructor(data?: PartialMessage<TestConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.TestConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "message", T: Status },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestConnectionResponse {
    return new TestConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestConnectionResponse {
    return new TestConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestConnectionResponse {
    return new TestConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TestConnectionResponse | PlainMessage<TestConnectionResponse> | undefined, b: TestConnectionResponse | PlainMessage<TestConnectionResponse> | undefined): boolean {
    return proto3.util.equals(TestConnectionResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.GetConnectionStatusRequest
 */
export class GetConnectionStatusRequest extends Message<GetConnectionStatusRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * if not provided, will return the status of a new connection.
   *
   * @generated from field: string connection_id = 2;
   */
  connectionId = "";

  constructor(data?: PartialMessage<GetConnectionStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.GetConnectionStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectionStatusRequest {
    return new GetConnectionStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectionStatusRequest {
    return new GetConnectionStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectionStatusRequest {
    return new GetConnectionStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectionStatusRequest | PlainMessage<GetConnectionStatusRequest> | undefined, b: GetConnectionStatusRequest | PlainMessage<GetConnectionStatusRequest> | undefined): boolean {
    return proto3.util.equals(GetConnectionStatusRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.GetConnectionStatusResponse
 */
export class GetConnectionStatusResponse extends Message<GetConnectionStatusResponse> {
  /**
   * @generated from field: autokitteh.common.v1.Status status = 1;
   */
  status?: Status;

  constructor(data?: PartialMessage<GetConnectionStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.GetConnectionStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "message", T: Status },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectionStatusResponse {
    return new GetConnectionStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectionStatusResponse {
    return new GetConnectionStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectionStatusResponse {
    return new GetConnectionStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectionStatusResponse | PlainMessage<GetConnectionStatusResponse> | undefined, b: GetConnectionStatusResponse | PlainMessage<GetConnectionStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetConnectionStatusResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.GetConnectionConfigRequest
 */
export class GetConnectionConfigRequest extends Message<GetConnectionConfigRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string connection_id = 2;
   */
  connectionId = "";

  constructor(data?: PartialMessage<GetConnectionConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.GetConnectionConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectionConfigRequest {
    return new GetConnectionConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectionConfigRequest {
    return new GetConnectionConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectionConfigRequest {
    return new GetConnectionConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectionConfigRequest | PlainMessage<GetConnectionConfigRequest> | undefined, b: GetConnectionConfigRequest | PlainMessage<GetConnectionConfigRequest> | undefined): boolean {
    return proto3.util.equals(GetConnectionConfigRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.integrations.v1.GetConnectionConfigResponse
 */
export class GetConnectionConfigResponse extends Message<GetConnectionConfigResponse> {
  /**
   * @generated from field: map<string, string> config = 1;
   */
  config: { [key: string]: string } = {};

  constructor(data?: PartialMessage<GetConnectionConfigResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.integrations.v1.GetConnectionConfigResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "config", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectionConfigResponse {
    return new GetConnectionConfigResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectionConfigResponse {
    return new GetConnectionConfigResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectionConfigResponse {
    return new GetConnectionConfigResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectionConfigResponse | PlainMessage<GetConnectionConfigResponse> | undefined, b: GetConnectionConfigResponse | PlainMessage<GetConnectionConfigResponse> | undefined): boolean {
    return proto3.util.equals(GetConnectionConfigResponse, a, b);
  }
}

