// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/program/v1/program.proto (package autokitteh.program.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Value } from "../../values/v1/values_pb.js";

/**
 * A specific location in code. Might be used for errors, entry points, etc.
 * Examples:
 *   foo.kitteh
 *   foo.kitteh:12.34
 *   foo.kitteh:OnSlack
 *   foo.kitteh:12.34,OnSlack
 *
 * @generated from message autokitteh.program.v1.CodeLocation
 */
export class CodeLocation extends Message<CodeLocation> {
  /**
   * @generated from field: string path = 1;
   */
  path = "";

  /**
   * @generated from field: uint32 row = 2;
   */
  row = 0;

  /**
   * @generated from field: uint32 col = 3;
   */
  col = 0;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  constructor(data?: PartialMessage<CodeLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.program.v1.CodeLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "row", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "col", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CodeLocation {
    return new CodeLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CodeLocation {
    return new CodeLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CodeLocation {
    return new CodeLocation().fromJsonString(jsonString, options);
  }

  static equals(a: CodeLocation | PlainMessage<CodeLocation> | undefined, b: CodeLocation | PlainMessage<CodeLocation> | undefined): boolean {
    return proto3.util.equals(CodeLocation, a, b);
  }
}

/**
 * @generated from message autokitteh.program.v1.CallFrame
 */
export class CallFrame extends Message<CallFrame> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: autokitteh.program.v1.CodeLocation location = 2;
   */
  location?: CodeLocation;

  constructor(data?: PartialMessage<CallFrame>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.program.v1.CallFrame";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "location", kind: "message", T: CodeLocation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallFrame {
    return new CallFrame().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallFrame {
    return new CallFrame().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallFrame {
    return new CallFrame().fromJsonString(jsonString, options);
  }

  static equals(a: CallFrame | PlainMessage<CallFrame> | undefined, b: CallFrame | PlainMessage<CallFrame> | undefined): boolean {
    return proto3.util.equals(CallFrame, a, b);
  }
}

/**
 * @generated from message autokitteh.program.v1.Error
 */
export class Error extends Message<Error> {
  /**
   * @generated from field: autokitteh.values.v1.Value value = 1;
   */
  value?: Value;

  /**
   * [0] is outermost.
   *
   * @generated from field: repeated autokitteh.program.v1.CallFrame callstack = 2;
   */
  callstack: CallFrame[] = [];

  /**
   * @generated from field: map<string, string> extra = 3;
   */
  extra: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Error>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.program.v1.Error";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "message", T: Value },
    { no: 2, name: "callstack", kind: "message", T: CallFrame, repeated: true },
    { no: 3, name: "extra", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Error {
    return new Error().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Error {
    return new Error().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Error {
    return new Error().fromJsonString(jsonString, options);
  }

  static equals(a: Error | PlainMessage<Error> | undefined, b: Error | PlainMessage<Error> | undefined): boolean {
    return proto3.util.equals(Error, a, b);
  }
}

